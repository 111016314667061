<div class="modal-dialog modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header">
      <h1 class="modal-title fs-5">{{ 'login' | translate }}</h1>
      <button type="button" class="btn-close" (click)="close(false)"></button>
    </div>

    <form class="root-form" [formGroup]="loginForm" (submit)="onLoginClick()">
      <div class="modal-body">
        <div class="title-wrapper">
          <!-- <img
        class="login-lock-img"
        src="/assets/imgs/login-lock.png"
        srcset="
          /assets/imgs/login-lock@2x.png 2x,
          /assets/imgs/login-lock@3x.png 3x
        "
      /> -->
          <span class="title"
            ><img class="company-logo" [src]="homepageLogoImageUrl"
          /></span>
        </div>

        <div class="login-container">
          <trnty-input-wrapper>
            <input
              type="text"
              autocomplete="username"
              [placeholder]="'Id' | translate"
              formControlName="id"
              (keydown)="onInputKeydown($event)"
              (input)="onInput($event)"
            />
            <span error>{{ 'VALID.id' | translate }}</span>
          </trnty-input-wrapper>

          <trnty-input-wrapper>
            <input
              [type]="isPasswordVisible ? 'text' : 'password'"
              autocomplete="current-password"
              formControlName="pw"
              [placeholder]="'Password' | translate"
              (keydown)="onInputKeydown($event)"
              (input)="onInput($event)"
            />
            <button
              type="button"
              class="btn btn-outline-dark pw-visibility"
              (click)="isPasswordVisible = !isPasswordVisible"
            >
              <i
                class="bi bi-eye{{ isPasswordVisible ? '' : '-slash' }}-fill"
              ></i>
            </button>
            <span error>{{ 'inputPw' | translate }}</span>
          </trnty-input-wrapper>
        </div>

        <!-- 로그인 안내문 -->
        <p
          class="login-guide text-body-secondary pt-2 ps-2"
          *ngIf="loginPageContent"
        >
          <small [innerText]="loginPageContent">
            <!-- {{ loginPageContent }} -->
          </small>
        </p>
      </div>

      <div class="modal-footer center">
        <button type="submit" class="btn btn-primary login-button" trnty-button>
          {{ 'login' | translate }}
        </button>

        <div class="option-wrapper">
          <div class="checkbox-wrapper">
            <!-- <mat-checkbox>
            <div>로그인 상태 유지</div>
          </mat-checkbox> -->
          </div>
          <div class="sub-button-wrapper">
            <a class="find-button" (click)="onFindInfoClick()">{{
              'Find Info' | translate
            }}</a>
            <span>|</span>
            <a class="join-button" (click)="onJoinClick()">
              {{ 'join' | translate }}
            </a>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
