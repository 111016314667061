import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { CommonModule } from '@angular/common';
import { catchError, Subscription, tap } from 'rxjs';
import { AuthService } from 'src/lib/auth/auth.service';
import { BrandService } from 'src/lib/services/brand.service';
import { HomeScreenService } from 'src/lib/services/home-screen.service';
import { DialogService } from '../../../services/dialog.service';
import { DialogAbstract } from '../../dialog.abstract';
import { InputWrapperModule } from '../../input-wrapper/input-wrapper.module';
import { SharedModule } from '../../shared/shared.module';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    InputWrapperModule,
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent
  extends DialogAbstract
  implements OnInit, OnDestroy
{
  @ViewChild('pwForm') pwForm?: ElementRef;

  dialogSubscription?: Subscription;

  authSubscription?: Subscription;

  loginForm = this.formBuilder.group({
    id: [null, Validators.required],
    pw: [null, Validators.required],
    // shouldSaveId: [null],
  });

  isPasswordVisible = false;

  homepageLogoImageUrl?: string;

  /**
   * 로그인 안내문
   */
  loginPageContent?: string;

  constructor(
    protected override elementRef: ElementRef<HTMLElement>,
    private router: Router,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private authService: AuthService,
    private brandService: BrandService,
    private homeScreenService: HomeScreenService,
  ) {
    super(elementRef);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.homepageLogoImageUrl =
      this.homeScreenService.homeScreen.homepageLogoImageFileData?.path ||
      this.homeScreenService.homeScreen.homepageLogoImageUrl ||
      this.brandService.brand.logoImgFileData?.path ||
      this.brandService.brand.logoImgUrl!;

    this.loginPageContent = this.homeScreenService.homeScreen.loginPageContent;
  }

  onLoginClick(): void {
    if (this.loginForm.invalid) {
      this.dialogSubscription = this.dialogService
        .alert(this.translateService.instant('ALERT.Required_ID_PW'))
        .subscribe();
      return;
    }

    const { id, pw } = this.loginForm.value;

    this.authSubscription = this.authService
      .login(id!, pw!, 'USERINFO', this.brandService.brand.id)
      .pipe(
        tap(() => {
          this.dialogRef.close(true);
        }),
        catchError(() => {
          return this.dialogService.alert(
            this.translateService.instant('ALERT.Login_Error'),
          );
        }),
      )
      .subscribe();
  }

  onFindInfoClick(): void {
    // FIXME: 정보 찾기 구현
    this.dialogService.alert('ALERT.Req_Accout_Travel_Agency').subscribe();
  }

  onJoinClick(): void {
    if (this.homeScreenService.homeScreen.userSignUp) {
      this.router.navigate(['join']).then(() => {
        this.dialogRef.close();
      });
    } else {
      this.dialogService.alert('ALERT.Req_Register_Travel_Agency').subscribe();
    }
  }

  onInputKeydown(event: KeyboardEvent): void {
    if (event.code === 'Space') {
      // 공백 입력 무시
      event.preventDefault();
    }
  }

  onInput(event: Event): void {
    const inputEvent = event as InputEvent;
    if (inputEvent.inputType.includes('insertFromPaste')) {
      // 붙어넣기 이벤트 발생
      const element = inputEvent.target as HTMLInputElement;
      // 공백 제거
      element.value = element.value.replace(/\s+/g, '');
    }
  }

  /**
   * ngOnDestroy로 해제
   */
  override ngOnDestroy(): void {
    super.ngOnDestroy();

    this.authSubscription?.unsubscribe();
    this.dialogSubscription?.unsubscribe();
  }
}
